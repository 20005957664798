import React, { useEffect } from 'react'
import { handleClientScriptLoad } from 'next/script'
import { FUNCTIONAL_COOKIE_CATEGORY } from 'composable/components/general/constants'
import { getScriptType } from '../../../helpers/utils/appendScript'

const SalesFloorChat = () => {
  useEffect(() => {
    handleClientScriptLoad({
      src: getScriptUrl(),
      className: FUNCTIONAL_COOKIE_CATEGORY,
      id: 'salesfloorscript',
      type: getScriptType(FUNCTIONAL_COOKIE_CATEGORY),
      async: true,
    })
  }, [])

  const getScriptUrl = () => {
    if (window?.location?.hostname == 'www.dxl.com') {
      return '/scripts/salesfloor_prod.js'
    } else {
      return '/scripts/salesfloor_stage.js'
    }
  }

  return <></>
}

export default SalesFloorChat
