import atgAxiosInstance from 'helpers/axios-atg-helper'
import useSWR, { mutate } from 'swr'
import { revalidateOptions } from 'frontastic'
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub'

export const storeStateDetails = () => {
  return useSWR('/action/channel/storeState', fetchApiHub, revalidateOptions)
}

export const getStore = async (obj: any) => {
  if (obj?.lat && obj.radius) {
    try {
      const CTStoresRes = await fetchApiHub(
        '/action/channel/getStore',
        {
          method: 'POST',
        },
        obj,
      )

      if (Array.isArray(CTStoresRes) && CTStoresRes.length > 0) {
        // Remove unnecessary properties before ATG store search call
        delete obj.edp
        delete obj.isPDPPage
        obj.lat = String(obj.lat)
        obj.lng = String(obj.lng)

        // Fetch ATG stores
        const ATGStoresResponse = await atgAxiosInstance.post('stores/search', obj)
        const ATGStores = ATGStoresResponse.data.stores

        // Add storeId from ATG to CTStoresRes
        if (Array.isArray(ATGStores)) {
          CTStoresRes.forEach((ctStore) => {
            const matchingStore = ATGStores.find(
              (atgStore) => ctStore.key === atgStore.displayStoreNumber.split(' ')[1],
            )

            if (matchingStore) {
              ctStore.storeId = matchingStore.storeId
              ctStore.storeNumber = matchingStore.storeNumber
            }
          })
        }
      }

      return mutate('/action/channel/getStore', CTStoresRes, { revalidate: false })
    } catch (error) {
      console.error('Error fetching stores:', error)
    }
  }
}

type GetStoreByKeyParams = {
  key: string
}

export const getStoreByKey = async ({ key }: GetStoreByKeyParams) => {
  try {
    const CTStoresRes = await fetchApiHub(
      '/action/channel/getStoreByKey',
      {
        method: 'POST',
      },
      { key },
    )

    return mutate('/action/channel/getStoreByKey', CTStoresRes, { revalidate: false })
  } catch (error) {
    console.error('Error fetching stores:', error)
    throw error // Optionally rethrow to handle the error further up
  }
}

export const storeState = async (storeObj: any) => {
  return mutate('/action/channel/storeState', storeObj, { revalidate: false })
}
