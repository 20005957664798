export class UtagManager {
  name: string
  running: boolean
  speed: number
  processQueue: any
  items: any
  window: any
  constructor(n, s, window, cb) {
    this.window = window
    this.name = n
    this.running = false
    this.speed = s || 100
    this.processQueue = cb
    this.items =
      this.window.localStorage && this.window.localStorage.getItem(this.name)
        ? JSON.parse(this.window.localStorage.getItem(this.name))
        : []
    if (!this.running && !this.isEmpty() && this.processQueue) {
      this.processQueue()
    }
  }
  enqueue(element) {
    this.items.push(element)
    if (this.window.localStorage) {
      this.window.localStorage.setItem(this.name, JSON.stringify(this.items))
    }
    if (!this.running && this.processQueue) {
      this.processQueue()
    }
  }
  dequeue() {
    this.items.shift()
    if (this.window.localStorage) {
      this.window.localStorage.setItem(this.name, JSON.stringify(this.items))
    }
  }
  front() {
    return this.items[0]
  }
  isEmpty() {
    return this.items.length < 1
  }
}
