import React, { createContext, useContext, useRef } from 'react'
import { AppProps } from 'next/app'
import { QueryClientProvider, QueryClient, HydrationBoundary, QueryClientConfig } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { UseDisclosureReturn, useDisclosure } from '@chakra-ui/react'
import { HOME_BREADCRUMB } from '../general/constants'

export interface ComposableContextInterface {
  cartDrawer: UseDisclosureReturn
  megaDrawer: UseDisclosureReturn
  accountDrawer: UseDisclosureReturn
  path: {
    getHome: () => string
    getPDP: (params: { slug: string }) => string
    getPLP: (params: string) => string
  }
}

const ComposableContext = createContext<ComposableContextInterface | undefined>(undefined)

const MINUTE = 1000 * 60

const queryConfigDefault: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 2.5 * MINUTE,
      // gctime: Garbage collection time - kicks in as soon as the query becomes unused.
      // After the time has passed, data will be "garbage collected" to avoid the cache from growing.
      gcTime: 5 * MINUTE,
      refetchOnWindowFocus: false,
      refetchOnMount: 'always',
    },
  },
}

export type ComposableProviderProps = Partial<ComposableContextInterface> &
  Partial<Pick<AppProps, 'pageProps'>> & {
    children: JSX.Element | JSX.Element[]
  }

export const ComposableProvider = ({ pageProps, children }: ComposableProviderProps) => {
  const cartDrawer = useDisclosure()
  const megaDrawer = useDisclosure()
  const accountDrawer = useDisclosure()
  const queryClientRef = useRef<QueryClient>()

  if (!queryClientRef.current) {
    queryClientRef.current = new QueryClient(queryConfigDefault)
  }

  const path = React.useRef<ComposableContextInterface['path']>({
    getHome: () => HOME_BREADCRUMB,
    getPDP: (params) => `/p/${params}`,
    getPLP: (params) => `/c/${params}`,
  })

  return (
    <ComposableContext.Provider
      value={{
        cartDrawer,
        megaDrawer,
        accountDrawer,
        path: path.current,
      }}
    >
      <QueryClientProvider client={queryClientRef.current}>
        <HydrationBoundary state={pageProps?.dehydratedState}>
          {children}
          <ReactQueryDevtools initialIsOpen={false} />
        </HydrationBoundary>
      </QueryClientProvider>
    </ComposableContext.Provider>
  )
}

export const useComposable = () => {
  const context = useContext(ComposableContext)
  if (context === undefined) {
    throw new Error('useComposable must be used within a ComposableProvider')
  }
  return context
}
