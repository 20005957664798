import { useEffect } from 'react'
import { handleClientScriptLoad } from 'next/script'
import { FUNCTIONAL_COOKIE_CATEGORY } from 'composable/components/general/constants'
import { getIsBrowser } from 'composable/helpers/hooks/useIsBrowser'
import { breadCrumbsBuilder, findPageType } from 'helpers/utils/monetateHelper'
import { getScriptType } from '../../../helpers/utils/appendScript'

const DOMAIN = process.env.NEXT_PUBLIC_MONETATE_DOMAIN
const NAME = process.env.NEXT_PUBLIC_MONETATE_NAME
const INSTANCE = process.env.NEXT_PUBLIC_MONETATE_INSTANCE

const account = {
  domain: DOMAIN,
  name: NAME,
  instance: INSTANCE,
}

export type cartRow = {
  productId: string
  quantity: number
  unitPrice: string
  sku: string | undefined
}
export type productDetails = {
  productId: string
  sku: string
}

type MonetateData = {
  setPageType: string[]
  addBreadcrumbs: string[]
  addCartRows: cartRow[]
}
type CustomVariable = {
  name: string
  value: string
}

interface MonetateProductData extends MonetateData {
  addProductDetails: productDetails[]
  addCategories: string[]
}

const Monetate = () => {
  useEffect(() => {
    handleClientScriptLoad({
      type: getScriptType(FUNCTIONAL_COOKIE_CATEGORY),
      className: FUNCTIONAL_COOKIE_CATEGORY,
      id: 'monetateScript',
      async: true,
      src:
        'https://se.monetate.net/js/2/' + account.name + '/' + account.instance + '/' + account.domain + '/custom.js',
    })
  }, [])
  return null
}

const pushMonetateData = async (monetateData: MonetateData) => {
  if (getIsBrowser()) {
    window.monetateQ = window.monetateQ || []
    for (let key in monetateData) {
      window.monetateQ.push([key, monetateData[key]])
    }
    window.monetateQ.push(['trackData'])
  }
}

const pushMonetateProductData = async (monetateData: any) => {
  if (getIsBrowser()) {
    window.monetateQ = window.monetateQ || []
    for (let key in monetateData) {
      window.monetateQ.push([key, monetateData[key]])
    }
    window.monetateQ.push(['trackData'])
  }
}

const buildMonetateProductDetailPageData = (pageWiseData: any) => {
  let productDetail: productDetails = {
    productId: pageWiseData.productId,
    sku: pageWiseData.sku,
  }
  const productDetailsArray = [].concat(productDetail)
  const categoryNameArray = [].concat(pageWiseData?.breadCrumbs)
  const breadcrumbsArray = ['Home', ...pageWiseData?.breadCrumbs]
  return { productDetailsArray, categoryNameArray, breadcrumbsArray }
}

const populateCustomVariables = (pageType: string, breadcrumbsArray: string[]): CustomVariable[] => {
  const customVariablesArray: CustomVariable[] = []
  if (pageType === 'brand') {
    const customVariable: CustomVariable = {
      name: 'brandname',
      value: !!breadcrumbsArray ? breadcrumbsArray[breadcrumbsArray.length - 1] : '',
    }
    customVariablesArray.push(customVariable)
    return customVariablesArray
  }

  breadcrumbsArray?.map((br, index) => {
    const customVariable = {
      name: `catlevel${index}`,
      value: br,
    }
    customVariablesArray.push(customVariable)
  })

  if (pageType == 'subsubcategory') {
    const customVariable: CustomVariable = {
      name: 'categoryid',
      value: !!breadcrumbsArray ? breadcrumbsArray[breadcrumbsArray.length - 1] : '',
    }
    customVariablesArray.push(customVariable)
  }
  customVariablesArray.shift()
  return customVariablesArray
}
const buildMonetateProductListingPageData = (pageWiseData: any, pageType: string) => {
  const categoryNameArray = [].concat(pageWiseData?.breadCrumbs).slice(1)
  const breadcrumbsArray = [].concat(pageWiseData?.breadCrumbs)
  const customVariablesArray = populateCustomVariables(pageType, breadcrumbsArray)
  return { categoryNameArray, breadcrumbsArray, customVariablesArray }
}

const buildMonetateSearchPageTypeData = (pageWiseData: any, pageType: string) => {
  const categoryNameArray = [].concat(pageWiseData?.breadCrumbs).slice(1)
  const breadcrumbsArray = [].concat(pageWiseData?.breadCrumbs)
  const searchData = {
    searchTerm: pageWiseData?.searchKeyword,
    searchType: 'site',
  }
  return { categoryNameArray, breadcrumbsArray, searchData }
}

export const monetateView = async (cartData: any, path: string, pageWiseData?: any) => {
  const cartRows = []
  const pageType = findPageType(path, pageWiseData?.breadCrumbs, pageWiseData?.isPlpSearchPage)
  if (cartData) {
    cartData?.items?.map((row, i) => {
      let cartRow: cartRow = {
        productId: row.productId,
        quantity: row.quantity,
        unitPrice: row.dxlCartGeneralInfo.unitAmount,
        sku: row.sku !== undefined ? row.sku.id : undefined,
      }
      cartRows.push(cartRow)
    })
  }

  if (pageType === 'product') {
    const { productDetailsArray, categoryNameArray, breadcrumbsArray } =
      buildMonetateProductDetailPageData(pageWiseData)
    const pageTypeArray = [pageType]
    await pushMonetateProductData({
      setPageType: pageTypeArray,
      addBreadcrumbs: breadcrumbsArray,
      addCartRows: cartRows,
      addProductDetails: productDetailsArray,
      addCategories: categoryNameArray,
    })
  } else if (pageType === 'subsubcategory' || pageType === 'plp' || pageType === 'brand') {
    const { categoryNameArray, breadcrumbsArray, customVariablesArray } = buildMonetateProductListingPageData(
      pageWiseData,
      pageType,
    )
    const productIdArray = pageWiseData?.productIdArray
    await pushMonetateProductData({
      setPageType: [pageType, 'recentlyviewed'],
      addBreadcrumbs: breadcrumbsArray,
      addProducts: productIdArray,
      addCategories: categoryNameArray,
      setCustomVariables: customVariablesArray,
    })
  } else if (pageType === 'search' || pageType === 'nosearch') {
    const productIdArray = pageWiseData?.productIdArray
    const { categoryNameArray, breadcrumbsArray, searchData } = buildMonetateSearchPageTypeData(pageWiseData, pageType)
    await pushMonetateProductData({
      setPageType: [pageType, 'recentlyviewed'],
      addBreadcrumbs: breadcrumbsArray,
      addProducts: productIdArray,
      addCategories: categoryNameArray,
      addSearch: searchData,
    })
  } else {
    const breadCrumbs = breadCrumbsBuilder(path, pageType)
    const pageTypeArray = [pageType]
    await pushMonetateData({
      setPageType: pageTypeArray,
      addBreadcrumbs: breadCrumbs,
      addCartRows: cartRows,
    })
  }
}

export default Monetate
