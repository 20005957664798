import { defineStyleConfig } from '@chakra-ui/react'

export default defineStyleConfig({
  baseStyle: {
    borderRadius: '1px',
    control: {
      color: 'white',
      backgroundColor: 'white',
      borderColor: 'shading.300',
      _checked: {
        color: 'white',
        borderColor: 'brand.primary',
        backgroundColor: 'brand.primary',
        _before: {
          w: 1,
          h: 1,
        },
        _hover: {
          backgroundColor: 'brand.primary',
          borderColor: 'brand.primary',
        },
      },
      _invalid: {
        borderColor: 'danger.600',
      },
      _disabled: {
        borderColor: 'shading.300',
        backgroundColor: 'shading.200',
        _checked: {
          backgroundColor: 'shading.400',
          borderColor: 'shading.200',
        },
      },
      _before: {
        w: 1,
        h: 1,
      },
    },
  },
})
