import { TransformedFormat } from '.'

export const mergeTextStyles = ({
  baseStyle,
  lgStyle,
}: {
  baseStyle: TransformedFormat
  lgStyle: TransformedFormat
}): { [key: string]: { base: string; lg: string } } => {
  return {
    fontFamily: { base: baseStyle.fontFamily, lg: lgStyle.fontFamily },
    fontWeight: { base: baseStyle.fontWeight, lg: lgStyle.fontWeight },
    lineHeight: { base: baseStyle.lineHeight, lg: lgStyle.lineHeight },
    fontSize: { base: baseStyle.fontSize, lg: lgStyle.fontSize },
    letterSpacing: { base: baseStyle.letterSpacing, lg: lgStyle.letterSpacing },
    textIndent: { base: baseStyle.textIndent, lg: lgStyle.textIndent },
    textTransform: { base: baseStyle.textTransform, lg: lgStyle.textTransform },
    textDecoration: {
      base: baseStyle.textDecoration,
      lg: lgStyle.textDecoration,
    },
  }
}
