import { extendTheme } from '@chakra-ui/react'
import * as components from './theme/components'
import { foundations } from './theme/foundations'
import { colors } from './theme/foundations/colors'
import { semanticTokens } from './theme/foundations/semanticTokens'
import { layerStyles } from './theme/layerStyles'
import { styles } from './theme/styles'
import { textStyles } from './theme/textStyles'

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-26px)',
}

export const theme = extendTheme({
  ...foundations,
  textStyles, // text variants
  layerStyles, // style variants
  styles, // Global style override
  colors,
  semanticTokens,
  components: {
    ...components,
    ...{
      Form: {
        variants: {
          floating: {
            container: {
              _focusWithin: {
                label: {
                  ...activeLabelStyles,
                },
              },
              'input:not(:placeholder-shown) + label': {
                ...activeLabelStyles,
              },
              label: {
                top: 0,
                left: 0,
                zIndex: 111,
                position: 'absolute',
                backgroundColor: 'white',
                pointerEvents: 'none',
                mx: 3,
                px: 1,
                my: 4,
                transformOrigin: 'left top',
              },
            },
          },
        },
      },
    },
  },
})
