import { Address } from '@Types/account/Address'
import { Cart } from '@Types/cart/Cart'
import { Discount } from '@Types/cart/Discount'
import { LineItem } from '@Types/cart/LineItem'
import { Payment } from '@Types/cart/Payment'
import { Variant } from '@Types/product/Variant'
import { OrderFilter } from '@Types/query/OrderFilter'
import useSWR, { mutate } from 'swr'
import { revalidateOptions } from 'frontastic'
import { fetchApiHub } from 'frontastic/lib/fetch-api-hub'

export type CartDetails = {
  account?: { email: string }
  shipping?: Address
  billing?: Address
  addressSetByPosBroker?: boolean
  removeInitializedByStoreMobileDevice?: boolean
  savedAndSuspendedCheckout?: boolean
}

export const cartItems = () => {
  return useSWR('/action/cart/getCart', fetchApiHub, revalidateOptions)
}

export const checkout = async () => {
  try {
    const res = await fetchApiHub('/action/cart/checkout', {
      method: 'POST',
    })
    mutate('/action/cart/getCart', res)
    return { order: res }
  } catch (err) {
    console.log('Error', err)
  }
}

export const orderHistory = async (filter?: OrderFilter) => {
  return await fetchApiHub('/action/cart/getOrders', { method: 'POST' }, { filter })
}

export const getAllCartDiscountCodes = async () => {
  return await fetchApiHub('/action/cart/getCartDiscounts', { method: 'POST' })
}

export const getProjectSettings = async () => {
  return await fetchApiHub('/action/project/getProjectSettings')
}

export const getShippingMethods = () => {
  return useSWR('/action/cart/getShippingMethods', fetchApiHub, revalidateOptions)
}

export const initializeCart = async (initializedByStoreMobileDevice?: boolean) => {
  return await fetchApiHub('/action/sts/initializeCart', { method: 'POST' }, { initializedByStoreMobileDevice })
}

export const setInstoreCart = async (cartId: string, associateId: string, storeId: string) => {
  if (!associateId && !storeId) {
    return await fetchApiHub(`/action/cart/setInstoreCart?cartId=${cartId}`)
  } else {
    return await fetchApiHub(
      `/action/cart/setInstoreCart?cartId=${cartId}&associateId=${associateId}&storeId=${storeId}`,
    )
  }
}

export const addItem = async (variant: Variant, quantity: number) => {
  const payload = {
    variant: {
      sku: variant.sku, // to test error handling, you can change this value to throw a Commeretools error
      count: quantity,
    },
  }
  const res = await fetchApiHub(
    '/action/cart/addToCart',
    {
      method: 'POST',
    },
    payload,
  )
  return mutate('/action/cart/getCart', res, { revalidate: false })
}

export const removeItem = async (lineItemId: string) => {
  const payload = {
    lineItem: { id: lineItemId },
  }

  const res = await fetchApiHub(
    '/action/cart/removeLineItem',
    {
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const updateItem = async (lineItemId: string, newQuantity: number) => {
  const payload = {
    lineItem: {
      id: lineItemId,
      count: newQuantity,
    },
  }
  const res = await fetchApiHub(
    '/action/cart/updateLineItem',
    {
      method: 'POST',
    },
    payload,
  )
  return mutate('/action/cart/getCart', res, { revalidate: false })
}

export const updateCartItems = async (lineItems: LineItem[]) => {
  const payload = {
    lineItems: lineItems,
  }

  const res = await fetchApiHub(
    '/action/cart/updateCartItems',
    {
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const updateCart = async (payload: CartDetails): Promise<Cart> => {
  const res = await fetchApiHub(
    '/action/cart/updateCart',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
  return res
}

export const setShippingMethod = async (shippingMethodId: string) => {
  const payload = {
    shippingMethod: {
      id: shippingMethodId,
    },
  }

  const res = await fetchApiHub(
    `/action/cart/setShippingMethod?shippingMethodId=${shippingMethodId}`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const redeemDiscountCode = async (code: string) => {
  const payload = {
    code: code,
  }
  const res = await fetchApiHub(
    `/action/cart/redeemDiscount`,
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const removeDiscountCode = async (discount: Discount) => {
  const payload = {
    discountId: discount.discountId,
  }
  const res = await fetchApiHub(
    '/action/cart/removeDiscount',
    {
      headers: {
        accept: 'application/json',
      },
      credentials: 'include',
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const getOrder = async (orderId: string) => {
  const payload = {
    orderId,
  }

  return await fetchApiHub(
    '/action/cart/getOrder',
    {
      method: 'POST',
    },
    payload,
  )
}

export const getCheckoutOrder = () => {
  return useSWR('/action/cart/getCheckoutOrder', fetchApiHub, revalidateOptions)
}

export const addPaymentByInvoice = async (payment: Payment) => {
  const payload = {
    payment,
  }
  const res = await fetchApiHub(
    '/action/cart/addPaymentByInvoice',
    {
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/addPaymentByInvoice', res)
  return res
}

export const recalculateCart = async () => {
  const res = await fetchApiHub(
    '/action/cart/recalculateCart',
    {
      method: 'POST',
    },
    {},
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const confirmCart = async (cartId: string) => {
  const res = fetchApiHub(`/action/cart/confirmCart?cartId=${cartId}`)
  return mutate('/action/cart/getCart', res, { revalidate: false })
}

export const setLineItemCustomData = async (
  lineItemId: string,
  customData: {
    quantity: number
    customizationDataCuff: string
    customizationDataInseam: string
    customizationCharge: number
    customizatioChargeTaxCode: string
  },
) => {
  const payload = {
    lineItemId,
    customData,
  }

  const res = await fetchApiHub(
    '/action/cart/setLineItemCustomData',
    {
      method: 'POST',
    },
    payload,
  )
  mutate('/action/cart/getCart', res, { revalidate: false })
}

export const getCartDiscountByKey = async (key: string) => {
  const payload = {
    key,
  }

  const res = await fetchApiHub(
    '/action/cart/getCartDiscountByKey',
    {
      method: 'POST',
    },
    payload,
  )

  return res
}
