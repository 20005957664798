const convertToCents = (dollarValue: number) => {
  dollarValue = dollarValue ? parseFloat(dollarValue.toFixed(2)) : dollarValue
  if (!dollarValue) {
    return 0
  } else if (dollarValue.toString().includes('.') && dollarValue.toString().split('.')[1].length === 1) {
    const centString = dollarValue.toString().replace('.', '')

    return parseInt(centString + '0')
  } else if (dollarValue.toString().includes('.')) {
    const centString = dollarValue.toString().replace('.', '')

    return parseInt(centString)
  } else {
    return parseInt(dollarValue.toString() + '00')
  }
}

export default convertToCents
