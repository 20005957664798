import ErrorBoundary from 'frontastic/lib/error-boundary'
import { CookieProBase } from 'components/headless/OneTrust'
import { COOKIE_PRO_VERSION_ID } from '../constants'

export const OneTrust = () => {
  return (
    <ErrorBoundary>
      <CookieProBase scriptVersionId={COOKIE_PRO_VERSION_ID} />
    </ErrorBoundary>
  )
}
