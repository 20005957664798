import { AlertProps } from '@chakra-ui/react'

const alertStyle = {
  baseStyle: (props: AlertProps) => {
    const { status } = props
    return {
      container: {
        backgroundColor: `${status === 'error' ? 'danger' : status}.100`,
        fontSize: '16px',
        padding: 3,
        gap: 3,
        borderLeft: '3px solid',
        borderColor: `${status === 'error' ? 'danger' : status}.500`,
        borderRadius: 'base',
        width: 'auto',
      },
      title: {
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '16.8px',
      },
      description: {
        fontWeight: 'regular',
        fontSize: '14px',
        lineHeight: '21px',
      },
      icon: {
        color: `${status === 'error' ? 'danger' : status}.500`,
      },
    }
  },
  variants: 'left-accent',
}

export default alertStyle
