const pageType = (relativePath: string, breadCrumbs?: string[], isSearchPage?: boolean) => {
  if (!!breadCrumbs) {
    if (breadCrumbs.find((item: string) => item === 'Brands') && relativePath === 'c') {
      return 'brand'
    }
    const breadCrumbsWithoutHome = breadCrumbs?.find((item: string) => item === 'Home')
      ? breadCrumbs.slice(1)
      : [...breadCrumbs]

    if (!!breadCrumbsWithoutHome && breadCrumbsWithoutHome.length > 2 && relativePath === 'c') {
      return 'subsubcategory'
    }
  }

  if (!!isSearchPage) {
    return 'search'
  }

  switch (relativePath) {
    case 'static':
      return 'static'
    case 'c':
      return 'plp'
    case 'p':
      return 'product'
    case '':
      return 'index'
    case 'no-results':
      return 'nosearch'
    default:
      return 'error'
  }
}

export const parsePathFragments = (routerUrlPath: string) => {
  const relativeUrlPathList = routerUrlPath.split('/').slice(1)
  const pathFragment =
    relativeUrlPathList[0]?.indexOf('?') > -1 ? relativeUrlPathList[0].split('?')[0] : relativeUrlPathList[0]
  return pathFragment
}

export const findPageType = (routerUrlPath: string, breadCrumbs?: string[], IsSearchPage?: boolean) => {
  const pathFragment = parsePathFragments(routerUrlPath)
  const page = pageType(pathFragment, breadCrumbs, IsSearchPage)
  return page
}

export const breadCrumbsBuilder = (routerUrlPath: string, pageType: string) => {
  const urlFragmentsList = routerUrlPath?.split('/').slice(1)
  if (urlFragmentsList) {
    switch (pageType) {
      case 'static':
        return staticPageBreadCrumbBuilder(urlFragmentsList)
      case 'index':
        return ['home']
    }
  }
  return []
}

export const staticPageBreadCrumbBuilder = (urlFragmentsList: string[]) => {
  const staticPageFragmentsList = urlFragmentsList
    .slice(1)
    .map((i) => i.split('#').flat())
    .flat()
  return staticPageFragmentsList
}
