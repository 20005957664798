import { fetchApiHub } from 'frontastic/lib/fetch-api-hub'

export const getProductsByIds = async (productIds: string[]) => {
  const payload = {
    productIds,
  }

  return await fetchApiHub(
    '/action/product/getProducts',
    {
      method: 'POST',
    },
    payload,
  )
}

export const getProductBySlug = async (productSlug: string) => {
  return await fetchApiHub(`/action/product/getProductBySlug?slug=${productSlug}`)
}

export const getProductForQuickView = async (key: string) => {
  return await fetchApiHub(`/action/product/getProductForQuickView?key=${key}`, {
    method: 'POST',
  })
}

export const getAllHemmingCharges = async (): Promise<any> => {
  return await fetchApiHub('/action/product/getAllHemmingCharges')
}

export const getInventoryOfProductBySku = async (sku: string) => {
  return await fetchApiHub(
    `/action/product/getInventory?sku=${sku}`,
    {
      method: 'POST',
    },
    {},
  )
}
