import { StyleConfig } from '@chakra-ui/react'

export enum CheckboxState {
  default,
  hover,
  invalid,
  disabled,
}

export const checkboxBgColor = {
  [CheckboxState.default]: {
    unchecked: 'surface.primary',
    checked: 'brand.primary',
  },
  [CheckboxState.hover]: {
    unchecked: 'surface.primary',
    checked: 'surface.primary',
  },
  [CheckboxState.invalid]: {
    unchecked: 'surface.primary',
    checked: 'brand.secondary',
  },
  [CheckboxState.disabled]: {
    unchecked: 'surface.highlight',
    checked: 'surface.highlight',
  },
}

export const Checkbox: StyleConfig = {
  baseStyle: {
    control: {
      borderColor: 'shading.300',
      background: checkboxBgColor[CheckboxState.default].unchecked,
      border: '1px',
      borderRadius: 'sm',
      _hover: { background: checkboxBgColor[CheckboxState.hover].unchecked, borderColor: 'brand.primary' },
      _disabled: {
        svg: {
          color: 'surface.primary',
        },
        borderColor: checkboxBgColor[CheckboxState.disabled].unchecked,
        background: checkboxBgColor[CheckboxState.disabled].unchecked,
        _checked: {
          background: checkboxBgColor[CheckboxState.disabled].checked,
          borderColor: checkboxBgColor[CheckboxState.disabled].unchecked,
        },
      },
      _checked: {
        background: checkboxBgColor[CheckboxState.default].checked,
      },
      _invalid: {
        iconColor: 'surface.primary',
        checkboxBgColor: checkboxBgColor[CheckboxState.invalid].checked,
      },
    },
  },
  sizes: {
    lg: {
      label: {
        fontSize: 'md',
      },
    },
  },
}
