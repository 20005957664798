export const getScriptType = (OneTrustClassName) => {
  if (OneTrustClassName && OneTrustClassName !== '') {
    let classType = OneTrustClassName
    classType = classType.split('-')[2]
    if ((window as any).OptanonActiveGroups) {
      let optanonActiveGroups = (window as any).OptanonActiveGroups
      if (!!optanonActiveGroups && optanonActiveGroups.indexOf(classType) !== -1) {
        return 'text/javascript'
      } else {
        return 'text/plain'
      }
    } else {
      return 'text/plain'
    }
  } else {
    return 'text/javascript'
  }
}

export const appendCommonScript = (scriptSrc, id, OneTrustClassName, callback, itemId) => {
  const scriptElem = document.createElement('script')
  scriptElem.src = scriptSrc
  scriptElem.setAttribute('itemId', itemId)
  if (OneTrustClassName && OneTrustClassName !== '') {
    let classType = OneTrustClassName
    classType = classType.split('-')[2]
    if ((window as any).OptanonActiveGroups) {
      let optanonActiveGroups = (window as any).OptanonActiveGroups
      if (!!optanonActiveGroups && optanonActiveGroups.indexOf(classType) !== -1) {
        scriptElem.type = 'text/javascript'
      } else {
        scriptElem.type = 'text/plain'
      }
    } else {
      scriptElem.type = 'text/plain'
    }
  } else {
    scriptElem.type = 'text/javascript'
  }

  if (OneTrustClassName !== null && OneTrustClassName !== 'undefined') {
    scriptElem.className = OneTrustClassName
  }
  scriptElem.async = true
  if (callback) {
    scriptElem.onload = callback(this)
  }
  scriptElem.id = id
  document.querySelector('head').appendChild(scriptElem)
}
