import atgAxiosInstance from '../../helpers/axios-atg-helper'
import { useAtgLegacyCart, useAtgUser } from 'frontastic/contexts'
import { SESSION_TIMEOUT_DURATION } from './general/constants'
import { useEffect } from 'react'

const SessionTimeout = () => {
  const { userSessionData } = useAtgUser()
  const { refreshCart, cartTotalItems, cartData } = useAtgLegacyCart()

  let userActivity: any
  const callTimeout = (request) => {
    if (request?.baseURL?.indexOf('public/v1') > -1) {
      if (
        (userSessionData?.firstName || (cartData && cartData?.itemsInCart !== 0)) &&
        window.location?.href?.indexOf('st=y') == -1
      ) {
        userActivity = setTimeout(() => {
          clearTimeout(userActivity)
          window.location.href = window.location.origin + '?st=y'
        }, SESSION_TIMEOUT_DURATION)
      }
    }
  }
  atgAxiosInstance.interceptors.request.use(
    (request) => {
      if (request) {
        callTimeout(request)
      }
      return request
    },
    (error) => {
      return Promise.reject(error)
    },
  )

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search)
    if (searchParams.get('st') === 'y') {
      refreshCart()
    }
  }, [])

  return null
}

export default SessionTimeout
