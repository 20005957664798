const SelectStyle = {
  // Styles for the base style
  parts: ['field', 'icon'],
  baseStyle: {
    /**
     * Styles set within { variants } will override base styles
     * Styles set within { sizes } will override base styles
     * The Input component uses "md" size and "outline" variant by default.
     *
     * You can unset those defaults by using null in defaultProps:
     *    defaultProps: {
     *      size: null,
     *      variant: null
     *    },
     *
     * You will lose all default styles this way, including things like focus.
     */
    field: {
      paddingBottom: 'auto',
      backgroundColor: 'white',
      paddingLeft: 3,
      paddingRight: 12,
    },
    icon: {
      right: 3,
    },
  },
  // // Styles for the size variations
  // sizes: {},
  // // Styles for the visual style variations
  variants: {},
}

export default SelectStyle
