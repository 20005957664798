import React, { useEffect } from 'react'
import Script, { handleClientScriptLoad } from 'next/script'
import { FUNCTIONAL_COOKIE_CATEGORY } from 'composable/components/general/constants'
import { getScriptType } from '../../../helpers/utils/appendScript'

const SurflyChat = () => {
  const hideChatBubble = () => {
    const intervalId = setInterval(() => {
      const elements: any = document.querySelectorAll('.ie-div-position-customer-chat')
      if (elements.length > 0) {
        clearInterval(intervalId)
        const element = elements[0]
        element.style.display = 'none'
      }
    }, 500)
  }
  useEffect(() => {
    initializeSurflyChat()
  }, [])

  const addChatObj = () => {
    // Define the function
    const textContent = `
          window.openChat = function() {
            window.open('https://home-c36.nice-incontact.com/inContact/ChatClient/ChatClient.aspx?poc=43f6a29f-b5a7-4c60-ae30-2e72e59698cb&bu=4600462',
            'icPatronChatWin',
             'location=no,height=630,menubar=no,status-no,width=410');
          };
        `
    return textContent
  }

  const initializeSurflyChat = () => {
    const win: any = window
    const surflyWidgetKey = win?.surflyWidgetKey
    if (typeof surflyWidgetKey == 'undefined') {
      const script = document.createElement('script')
      script.src = '/scripts/surfly_chat.js'
      script.async = true
      const scriptSrcVal = script.src
      handleClientScriptLoad({
        src: scriptSrcVal,
        id: 'surflyChatScript',
        className: FUNCTIONAL_COOKIE_CATEGORY,
        type: getScriptType(FUNCTIONAL_COOKIE_CATEGORY),
        strategy: 'lazyOnload',
        async: true,
      })
    }
  }

  return (
    <>
      <Script id="surflyChatScript">{addChatObj()}</Script>
    </>
  )
}

export default SurflyChat
