import { useMemo } from 'react'
import { TasticWrapperProps } from 'frontastic'
import { injectDataSources } from 'frontastic/lib/utils/inject-data-sources'
import ErrorBoundary from '../../frontastic/lib/error-boundary'

export function ComposableTasticWrapper(props: TasticWrapperProps) {
  const { tastics, data, dataSources, highlight = false, isMobileDevice = false } = props
  const TasticToRender: React.ElementType = tastics[data.tasticType] || tastics['default']
  // inject all datasources into the proper nodes
  // dataSources null check satisfies TS
  const updatedBlock = useMemo(
    () => (dataSources ? injectDataSources(data.configuration, dataSources, isMobileDevice) : data.configuration),
    [data.configuration, dataSources, isMobileDevice],
  )

  const tasticType = useMemo(() => data?.tasticType, [data?.tasticType])
  const tasticId = useMemo(() => data?.tasticId, [data?.tasticId])
  const pageFolder = useMemo(() => props.pageFolder, [props.pageFolder])

  return (
    <ErrorBoundary>
      <TasticToRender type={tasticType} id={tasticId} data={updatedBlock} pageFolder={pageFolder} />
    </ErrorBoundary>
  )
}
