const localizationMapper = {
  //   Customize the mapper to meet your projects needs
  en: {
    locale: 'en_US',
    currency: 'USD',
    currencyCode: '$',
    countryCode: 'US',
    countryName: 'United States of America',
  },
};

const getLocalizationInfo = (locale) => {
  // Check if locale is empty, undefined, or null
  if (!locale) {
    console.warn(
      `Locale not provided or invalid. Defaulting to 'en'.`
    );
    return localizationMapper.en;
  }

  // Check if the provided locale exists in localizationMapper
  if (!(locale in localizationMapper)) {
    console.warn(
      `Invalid locale ${locale} provided. Possible values are ${Object.keys(localizationMapper).join(', ')}`,
    );
    return localizationMapper.en;
  }

  // Return the localization info for the provided locale
  return localizationMapper[locale];
};

module.exports = { getLocalizationInfo };

