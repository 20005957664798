/* eslint-disable import/order */
import { ChakraProvider } from '@chakra-ui/react'
import { theme } from 'composable/chakra'
import { ComposableProvider } from 'composable/components/composable-provider'
import { Datadog } from 'composable/components/datadog'
import { ThirdPartyScripts } from 'composable/components/dxl-footer/thirdPartyScripts'
import { OneTrust } from 'composable/components/general/components/OneTrust'
import { APP_VERSION } from 'composable/components/general/constants'
import SessionTimeout from 'composable/components/session-timeout'
import { FrontasticProvider } from 'frontastic'
import { AtgCartProvider, AtgUserProvider, StoreProvider } from 'frontastic/contexts'
import Monetate from 'frontastic/tastics/monetate'
import SurflyChat from 'frontastic/tastics/surfly-chat'
import Tealium from 'frontastic/tastics/tealium'
import { appWithTranslation } from 'next-i18next'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { sdk } from 'sdk'

import '../styles/app.css'
import '../styles/atg/main.css'
import '../styles/components/default-loader.css'
import '../styles/components/slider.css'
import '../styles/themes/default.css'
import '../styles/themes/theme1.css'
import '../styles/themes/theme2.css'
import '../styles/themes/theme3.css'
import SalesFloorChat from 'frontastic/tastics/sales-floor-chat'
import dynamic from 'next/dynamic'

const Drawer = dynamic(() =>
  APP_VERSION === 'R1'
    ? import('../atg/components/legacy-cart-drawer').then((mod) => mod.LegacyCartDrawer)
    : import('composable/components/cart/cart-drawer/cart-drawer').then((mod) => mod.CartDrawer),
)

const SignInSignUpGlobal = dynamic(() => import('frontastic/tastics/global-signin-signup'))

const AccountDrawer = dynamic(() =>
  import('composable/components/account/account-drawer').then((mod) => mod.AccountDrawer),
)

function FrontasticStarter({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const path = router.asPath

  useEffect(() => {
    if (window.Yo && path == '/') {
      window.Yo.pubsub.publish({ topic: 'rum/spa/transition/start', message: {} })
    }
  }, [path])

  sdk.configureForNext(router.locale as string)

  return (
    <FrontasticProvider>
      <ComposableProvider pageProps={pageProps}>
        <AtgUserProvider>
          <AtgCartProvider>
            <StoreProvider>
              <ChakraProvider theme={theme}>
                <Datadog />
                <OneTrust />
                <SessionTimeout />
                <Monetate />
                <Component {...pageProps} />
                <AccountDrawer />
                <Drawer />
                <SignInSignUpGlobal />
                <ThirdPartyScripts />
                <Tealium />
                <SalesFloorChat />
                <SurflyChat />
              </ChakraProvider>
            </StoreProvider>
          </AtgCartProvider>
        </AtgUserProvider>
      </ComposableProvider>
    </FrontasticProvider>
  )
}

export default appWithTranslation(FrontasticStarter)
