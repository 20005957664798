import { useEffect, useState } from 'react'

export const getIsBrowser = () => {
  return typeof window !== 'undefined' && typeof window.document !== 'undefined'
}

export const useIsBrowser = () => {
  const [isBrowser, setIsBrowser] = useState(false)

  useEffect(() => {
    setIsBrowser(getIsBrowser())
  }, [])

  return isBrowser
}
