import { Styles } from '@chakra-ui/theme-tools'
import { semantic } from '../figma-tokens'

export const styles: Styles = {
  global: {
    body: {
      color: semantic.text.primary,
    },
  },
}
