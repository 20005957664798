import { Styles } from '@chakra-ui/theme-tools'
import { borders } from '../foundations/borders'
import { borderStyles } from '../foundations/borderStyles'

const InputStyle = {
  // Styles for the base style
  parts: ['field', 'addon', 'group', 'element'],
  baseStyle: {
    /**
     * Styles set within { variants } will override base styles
     * Styles set within { sizes } will override base styles
     * The Input component uses "md" size and "outline" variant by default.
     *
     * You can unset those defaults by using null in defaultProps:
     *    defaultProps: {
     *      size: null,
     *      variant: null
     *    },
     *
     * You will lose all default styles this way, including things like focus.
     */
    field: {
      // Add custom base styles here
      borderWidth: borders.sm,
      borderStyle: borderStyles.normal,
    },
  },
  // // Styles for the size variations
  // sizes: {},
  // // Styles for the visual style variations
  variants: {
    outline: (props: Styles) => {
      return {
        field: {
          borderColor: 'shading.300',
          bg: 'surface.primary',
          border: '1px',
          borderRadius: 'md',
          color: 'text.primary',
          _placeholder: {
            color: 'shading.500',
          },
          _invalid: {
            borderColor: 'danger.600',
            color: 'primary',
          },
          _focus: {
            border: '2px',
            borderWidth: borders.md,
            borderStyle: borderStyles.normal,
            borderColor: 'primary',
            color: 'brand.primary',
          },
          _disabled: {
            borderColor: 'shading.500',
            bgColor: 'shading.100',
            color: 'shading.500',
          },
        },
        addon: {
          _focus: {
            border: '2px',
            borderWidth: borders.md,
            borderStyle: borderStyles.normal,
            borderColor: 'primary',
            color: 'brand.primary',
          },
          _disabled: {
            color: 'shading.500',
            bgColor: 'surface.disabled',
          },
          _invalid: {
            color: 'brand.primary',
            borderColor: 'danger.600',
          },
        },
        element: {},
        group: {},
      }
    },
  },
  defaultProps: {
    variant: 'outline', // null here
  },
}

export default InputStyle
