import React, { useMemo } from 'react'
import { Container, Grid as ChakraGrid, GridItem, useBreakpointValue, VStack } from '@chakra-ui/react'
import { ComposableTasticWrapper } from 'composable/components/composable-tastic-wrapper'
import { APP_CONTEXT } from 'composable/components/general/constants'
import { GlobalStickyNavBar } from 'composable/components/global-sticky-nav-bar'
import { convertToRows, getGridItemSize } from 'composable/helpers/utils/grid-utils'
import { Errors } from './errors'
import { CellConfiguration, PageDataResponse, PagePreviewDataResponse, Tastic, TasticRegistry } from './types'

const renderTastics = (
  layoutElement: ReturnType<typeof convertToRows>[0][0],
  tastics: TasticRegistry,
  currentHighlight: string | undefined,
  data: PageDataResponse,
) =>
  layoutElement.tastics.map((t: Tastic) => (
    <ComposableTasticWrapper
      tastics={tastics}
      key={t.tasticId}
      data={t}
      dataSources={data.data.dataSources}
      pageFolder={data.pageFolder}
      highlight={currentHighlight === t.tasticId}
      isMobileDevice={data?.isMobileDevice}
    />
  ))

const renderGridItems = (
  row: ReturnType<typeof convertToRows>[0],
  tastics: TasticRegistry,
  getResponsiveGridItemSpanValue: (size: number) => number,
  shouldDisplay: (config: CellConfiguration) => boolean,
  currentHighlight: string | undefined,
  data: PageDataResponse,
) =>
  row.map((layoutElement) => {
    if (!shouldDisplay(layoutElement.configuration)) return null
    return (
      <GridItem
        key={layoutElement.layoutElementId}
        className={layoutElement.tastics[0]?.tasticType}
        gridColumn={`span ${getResponsiveGridItemSpanValue(layoutElement.configuration.size)}`}
        sx={{ '> div': { height: '100%', minHeight: 'auto' } }}
      >
        {renderTastics(layoutElement, tastics, currentHighlight, data)}
      </GridItem>
    )
  })

interface SectionGridProps {
  as: 'header' | 'main' | 'footer'
  rows: ReturnType<typeof convertToRows>
  tastics: TasticRegistry
  getResponsiveGridItemSpanValue: (size: number) => number
  shouldDisplay: (config: CellConfiguration) => boolean
  currentHighlight?: string
  data: PageDataResponse
}

const SectionGrid: React.FC<SectionGridProps> = ({
  as,
  rows,
  tastics,
  getResponsiveGridItemSpanValue,
  shouldDisplay,
  currentHighlight,
  data,
}) => (
  <VStack as={as} spacing={0}>
    {rows.map((row, i) => (
      <ChakraGrid key={i} w="full" gap="20px" gridTemplateColumns="repeat(12, 1fr)">
        {renderGridItems(row, tastics, getResponsiveGridItemSpanValue, shouldDisplay, currentHighlight, data)}
      </ChakraGrid>
    ))}
  </VStack>
)

export function FrontasticRenderer({
  data,
  tastics = {},
  gridClassName,
  wrapperClassName,
  currentHighlight,
}: {
  data: PageDataResponse & PagePreviewDataResponse
  tastics: TasticRegistry
  gridClassName?: string
  wrapperClassName?: string
  currentHighlight?: string
}) {
  const headerSectionRows = useMemo(
    () => convertToRows(data?.page?.sections?.head?.layoutElements),
    [data?.page?.sections?.head],
  )
  const mainSectionRows = useMemo(
    () => convertToRows(data?.page?.sections?.main?.layoutElements),
    [data?.page?.sections?.main],
  )
  const footerSectionRows = useMemo(
    () => convertToRows(data?.page?.sections?.footer?.layoutElements),
    [data?.page?.sections?.footer],
  )

  const getResponsiveGridItemSpanValue = useBreakpointValue({
    base: (originalSize: number) => getGridItemSize({ viewport: 'mobile', originalSize }),
    md: (originalSize: number) => getGridItemSize({ viewport: 'tablet', originalSize }),
    lg: (originalSize: number) => getGridItemSize({ viewport: 'desktop', originalSize }),
  })

  const shouldDisplay = useBreakpointValue({
    base: (config: CellConfiguration) => config.mobile,
    md: (config: CellConfiguration) => config.tablet,
    lg: (config: CellConfiguration) => config.desktop,
  })

  return (
    <Container
      maxWidth={'full'}
      padding={0}
      style={{ display: 'flex', minHeight: '100vh' }}
      flexDirection={'column'}
      sx={{ main: { flex: '1' } }}
    >
      {process && process.env.NODE_ENV !== 'production' && <Errors />}

      <SectionGrid
        as="header"
        rows={headerSectionRows}
        tastics={tastics}
        getResponsiveGridItemSpanValue={getResponsiveGridItemSpanValue}
        shouldDisplay={shouldDisplay}
        currentHighlight={currentHighlight}
        data={data}
      />

      <SectionGrid
        as="main"
        rows={mainSectionRows}
        tastics={tastics}
        getResponsiveGridItemSpanValue={getResponsiveGridItemSpanValue}
        shouldDisplay={shouldDisplay}
        currentHighlight={currentHighlight}
        data={data}
      />

      <SectionGrid
        as="footer"
        rows={footerSectionRows}
        tastics={tastics}
        getResponsiveGridItemSpanValue={getResponsiveGridItemSpanValue}
        shouldDisplay={shouldDisplay}
        currentHighlight={currentHighlight}
        data={data}
      />
    </Container>
  )
}
