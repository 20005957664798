import { Antonio, Libre_Franklin } from 'next/font/google'
import {
  fontSize,
  fontFamilies,
  fontWeights as fontWeightsTokens,
  lineHeights as lineHeightsTokens,
  letterSpacing as letterSpacingTokens,
} from '../../figma-tokens'

// IMPORTANT: Chakra UI CLI has a bug affecting theme typings and autocomplete.
// If you intend to run `pnpm theme`, you'll need to make code adjustments.
//
// Steps:
// 1. Comment out the 'Normal Usage' section below.
// 2. Uncomment the 'For pnpm theme Usage' section.
//
// This workaround is due to a known issue with Chakra UI CLI. For more details, visit:
// https://github.com/chakra-ui/chakra-ui/issues/7157#issuecomment-1399243083

// For `pnpm theme` Usage (Uncomment for running `pnpm theme`):
// const antonioFont = null,
//   libreFranklinFont = null

// Normal Usage (Comment out when running `pnpm theme`):

const antonioFont = Antonio({
  subsets: ['latin'],
  display: 'swap',
})

const libreFranklinFont = Libre_Franklin({
  subsets: ['latin'],
  display: 'swap',
})

export const availableFonts = {
  // fontFamily value are dynamically generated from the font file name,
  // so we can't use the fontFamilies tokens here
  primary: libreFranklinFont?.style?.fontFamily ?? 'Libre Franklin',
  secondary: antonioFont?.style?.fontFamily ?? 'Antonio',
  tertiary: fontFamilies?.tertiary ?? 'Arial',
}

export const fonts = {
  body: availableFonts.primary,
  heading: availableFonts.primary,
  mono: availableFonts.primary,
  //https://nextjs.org/docs/basic-features/font-optimization
  // define font family for Circular Std
  LibreFranklin: availableFonts.primary,
  'Libre Franklin': availableFonts.primary,
  Antonio: availableFonts.secondary,
}

// Need to match with chakra-ui Theme Key
export const typography = {
  fonts,
  fontSizes: fontSize,
  fontWeights: fontWeightsTokens,
  letterSpacings: letterSpacingTokens,
  lineHeights: lineHeightsTokens,
}
