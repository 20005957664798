import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { useQuery } from '@tanstack/react-query'
import { Cart } from '@Types/cart/Cart'
import { useFormat } from 'helpers/hooks/useFormat'
import { calculateCartCount } from 'helpers/utils/calculateCartCount'
import { myBag, myWishList } from './helpers/constants'
import {
  addItemAtgLegacyCart,
  addToWishlist,
  useFetchAtgLegacyCart,
  getAtgWishList,
  moveItemToWishlist,
  removeItemLegacyCart,
  updateItemLegacyCart,
} from './helpers/httpRequests'
import { formatCart, raiseToast, reversedItemsInCart } from './helpers/utils'
import { AtgLegacyCart } from './interfaces/AtgLegacyCartData'
import { useAtgUser } from '../atgUserContext'

interface IAtgCartContext {
  cartData: AtgLegacyCart
  wishListData?: Array<string>
  refreshCart: () => Promise<any>
  formattedCart: Partial<Cart> | undefined
  addItem: (
    productId: string,
    catalogRefId: string,
    quantity: number,
    title: string,
    hemmingLength?: string,
    storeNumber?: string,
    giftCardAmount?: number,
  ) => Promise<{ additemSuccess: boolean }>
  updateItem: (itemId: string, quantity: number, title: string) => Promise<void>
  removeItem: (itemId: string) => Promise<void>
  addToWishlistLegacyCart: (productId: string, skuId: string, quantity: number, title: string) => Promise<void>
  moveToWishList: (
    productId: string,
    skuId: string,
    quantity: number,
    commerceItems: any[],
    title: string,
  ) => Promise<void>
  getWishList: () => Promise<any>
  cartTotalItems: number
}

export const AtgCartContext = createContext<IAtgCartContext>({
  cartData: null,
  wishListData: null,
  refreshCart: async () => {},
  addItem: async (
    productId: string,
    catalogRefId: string,
    quantity: number,
    title: string,
    hemmingLength?: string,
    storeNumber?: string,
    giftCardAmount?: number,
  ) => Promise.resolve({ additemSuccess: false }),
  updateItem: async (itemId: string, quantity: number, title: string) => {},
  removeItem: async (itemId: string) => {},
  addToWishlistLegacyCart: async (productId: string, skuId: string, quantity: number, title: string) => {},
  moveToWishList: async (productId: string, skuId: string, quantity: number, commerceItems: any[], title: string) => {},
  getWishList: async () => {},
  formattedCart: undefined,
  cartTotalItems: 0,
})

export const AtgCartProvider = ({ children }) => {
  const wishListQuery = useQuery({
    queryKey: ['wishList'],
    queryFn: async () => {
      try {
        const res = await getAtgWishList()
        if (res.status === 200) {
          return res.data.productIds || []
        }
      } catch (err) {
        // as the wishlist is not something that prevents the user to keep
        // using the site, the system should not raise a toast
        console.error('Error trying to set wishlist')
      }
    },
  })
  const wishListData = wishListQuery.data

  const toast = useToast()
  const intl = useFormat({ name: 'common' })
  const { userSessionData, fetchUserSessionData } = useAtgUser()
  const cart = useFetchAtgLegacyCart({ orderId: userSessionData?.orderId ?? '' })
  const cartData: AtgLegacyCart | null = cart?.data ?? null
  const formattedCart = useMemo(() => formatCart(cartData, userSessionData), [userSessionData, cartData])
  const cartTotalItems = useMemo(() => calculateCartCount(formattedCart?.lineItems || []), [formattedCart])

  const addItem = async (
    productId: string,
    catalogRefId: string,
    quantity: number,
    title: string,
    hemmingLength?: string,
    storeNumber?: string,
    giftCardAmount?: number,
  ) => {
    try {
      const res = await addItemAtgLegacyCart({
        productId,
        catalogRefId,
        quantity,
        ...(hemmingLength && { hemmingLength }),
        ...(storeNumber && { storeNumber }),
        ...(giftCardAmount && { giftCardAmount: giftCardAmount.toString() }),
      })
      if (res.status === 200) {
        raiseToast(
          myBag,
          intl.formatMessage({
            id: 'cart.item.add.success',
            values: { name: title },
          }),
          'success',
          toast,
        )
        cart.refetch()
        return { additemSuccess: true }
      } else {
        raiseToast(myBag, intl.formatMessage({ id: 'cart.item.add.error', values: { name: title } }), 'error', toast)
        return { additemSuccess: false }
      }
    } catch (err) {
      raiseToast(myBag, intl.formatMessage({ id: 'cart.item.add.error', values: { name: title } }), 'error', toast)
      return { additemSuccess: false }
    }
  }

  const updateItem = async (itemId: string, quantity: number, title: string) => {
    try {
      const res = await updateItemLegacyCart({ itemId, quantity })
      if (res.status === 200) {
        cart.refetch()
      } else {
        raiseToast(
          myBag,
          intl.formatMessage({ id: 'cart.item.qty.update.error', values: { name: title } }),
          'error',
          toast,
        )
      }
    } catch (err) {
      raiseToast(
        myBag,
        intl.formatMessage({ id: 'cart.item.qty.update.error', values: { name: title } }),
        'error',
        toast,
      )
    }
  }

  const removeItem = async (itemId: string) => {
    try {
      const res = await removeItemLegacyCart({ itemId })
      if (res.status === 200) {
        cart.refetch()
      } else {
        raiseToast(
          myBag,
          intl.formatMessage({ id: 'cart.item.remove.error', values: { name: itemId } }),
          'error',
          toast,
        )
      }
    } catch (err) {
      raiseToast(myBag, intl.formatMessage({ id: 'cart.item.remove.error', values: { name: itemId } }), 'error', toast)
    }
  }

  const addToWishlistLegacyCart = async (productId: string, skuId: string, quantity: number, title: string) => {
    try {
      const res = await addToWishlist({ productId, skuId, quantity })
      if (res.status === 200) {
        raiseToast(
          myWishList,
          intl.formatMessage({ id: 'account.wishlist.add.success', values: { name: title } }),
          'success',
          toast,
        )
        cart.refetch()
      } else {
        raiseToast(
          myWishList,
          intl.formatMessage({ id: 'account.wishlist.add.error', values: { name: title } }),
          'error',
          toast,
        )
      }
    } catch (err) {
      raiseToast(
        myWishList,
        intl.formatMessage({ id: 'account.wishlist.add.error', values: { name: title } }),
        'error',
        toast,
      )
    }
  }

  const moveToWishList = async (
    productId: string,
    skuId: string,
    quantity: number,
    commerceItems: any[],
    title: string,
  ) => {
    try {
      const res = await moveItemToWishlist({ productId, skuId, quantity, commerceItems })
      if (res.status === 200) {
        raiseToast(
          myWishList,
          intl.formatMessage({ id: 'account.wishlist.moveTo.success', values: { name: title } }),
          'success',
          toast,
        )
        cart.refetch()
      } else {
        raiseToast(
          myWishList,
          intl.formatMessage({ id: 'account.wishlist.moveTo.error', values: { name: title } }),
          'error',
          toast,
        )
      }
    } catch (err) {
      raiseToast(
        myWishList,
        intl.formatMessage({ id: 'account.wishlist.moveTo.error', values: { name: title } }),
        'error',
        toast,
      )
    }
  }

  return (
    <AtgCartContext.Provider
      value={{
        cartData,
        wishListData,
        // refreshCart function removed
        // TODO: validate all invocations replaced by cart.refetch()
        refreshCart: cart.refetch,
        addItem,
        updateItem,
        removeItem,
        addToWishlistLegacyCart,
        moveToWishList,
        formattedCart,
        cartTotalItems,
        getWishList: wishListQuery.refetch,
      }}
    >
      {children}
    </AtgCartContext.Provider>
  )
}

export const useAtgLegacyCart = () => {
  const cartContext = useContext(AtgCartContext)

  if (!cartContext) {
    throw new Error('useAtgLegacyCart must be used within a AtgCartProvider')
  }

  return cartContext
}
