import React, { createContext, useState, useEffect, useCallback } from 'react'
import { useQuery } from '@tanstack/react-query'
import atgAxiosInstance from '../../../helpers/axios-atg-helper'
import { utagLink } from '../../../helpers/tealiumHelper'

const AtgUserContext = createContext(undefined)

export const AtgUserProvider = ({ children }) => {
  const fetchUserData = useCallback(async () => {
    try {
      const response = await atgAxiosInstance.get('currentUser/dxlUser', {
        withCredentials: true,
      })

      if (response.status !== 200) {
        throw new Error('Failed to fetch user details - status code: ' + response.status)
      }

      return response.data
    } catch (error) {
      console.error('Error fetching user session details:', error)
    }
  }, [])

  const fetchUserSessionData = async () => {
    try {
      const response = await atgAxiosInstance.get('currentUser/header', {
        withCredentials: true,
      })

      if (response.status !== 200) {
        throw new Error('Failed to fetch user details - status code: ' + response.status)
      }

      if (userDetails?.profile?.firstName) {
        const utagData = [
          'link',
          {
            tealium_event: 'user_detected',
            crm_customer_number: userDetails?.profile?.['crmId'] ? userDetails?.profile?.['crmId'] : '',
            customer_id: userDetails?.profile?.['customerId'] || '',
            user_loginstatus: true,
            signin_source: userSessionData?.socialLogin ? userSessionData?.socialLoginProvider : 'site',
          },
        ]

        utagLink(utagData)
      }
      return response.data
    } catch (error) {
      console.error('Error fetching user details:', error)
    }
  }

  const userSessionQuery = useQuery<any>({
    queryKey: ['userSessionData'],
    queryFn: fetchUserSessionData,
  })
  const userSessionData = userSessionQuery?.data || {}
  const isInternationalUser: boolean = userSessionData?.dxlCountry && userSessionData?.dxlCountry !== 'US'

  const userQuery = useQuery<any>({
    queryKey: ['userData'],
    queryFn: fetchUserData,
  })

  const userDetails = userQuery?.data || {}

  return (
    <AtgUserContext.Provider
      value={{
        userDetails,
        userSessionData,
        isInternationalUser,
        fetchUserData: userQuery.refetch,
        fetchUserSessionData: userSessionQuery.refetch,
      }}
    >
      {children}
    </AtgUserContext.Provider>
  )
}

export const useAtgUser = () => {
  const userDetails = React.useContext(AtgUserContext)

  if (!userDetails) {
    throw new Error('useUser must be used within a UserProvider')
  }

  return userDetails
}
