import { AxiosResponse } from 'axios'
import axiosRetry from 'axios-retry'
import { useQuery } from '@tanstack/react-query'
import { EXPERIAN_API_TOKEN_KEY } from 'composable/components/general/constants'
import atgAxiosInstance from '../../../../helpers/axios-atg-helper'
import { AtgAdditionalLineItemAttributes, AtgLegacyCart } from '../interfaces/AtgLegacyCartData'
import { reversedItemsInCart } from './utils'

// setting up axios-retry
axiosRetry(atgAxiosInstance, { retries: 3, retryDelay: (retryCount) => retryCount * 500 })

export const fetchAdditionalLineItemAttributes = async (): Promise<AtgAdditionalLineItemAttributes[] | undefined> => {
  try {
    const fetchAdditionalLineItems = await atgAxiosInstance.get('cart/additionalLineItemAttributes', {
      withCredentials: true,
    })

    if (fetchAdditionalLineItems.status === 200) {
      return fetchAdditionalLineItems.data
    }

    return undefined
  } catch (ex) {
    throw new Error('Failed to fetch ATG Legacy Cart - Additional Attributes')
  }
}

export const fetchCartGeneralInfo = async () => {
  try {
    const fetchGeneralInfo: AxiosResponse = await atgAxiosInstance.get('placeOrder/dxlCart', {
      withCredentials: true,
    })

    if (fetchGeneralInfo.status === 200) {
      return fetchGeneralInfo.data
    }

    return undefined
  } catch (ex) {
    throw new Error('Failed to fetch ATG Legacy Cart - General Info')
  }
}

export const updateCurrencyToUSD = async () => {
  try {
    const res: AxiosResponse = await atgAxiosInstance.get('globalService/init?e4x_auto=false', {
      withCredentials: true,
    })

    if (res.status === 200) {
      return res.data
    }

    return undefined
  } catch (ex) {
    throw new Error('Failed to fetch ATG Legacy Cart - General Info')
  }
}

export const addItemAtgLegacyCart = async ({
  productId,
  catalogRefId,
  quantity,
  hemmingLength,
  storeNumber,
  giftCardAmount,
}: {
  catalogRefId: string
  productId: string
  quantity: number
  hemmingLength?: string
  storeNumber?: string
  giftCardAmount?: string
}) => {
  try {
    const res = await atgAxiosInstance.post(
      `cart/commerceItems/dxlCommerceitems`,
      { productId, catalogRefId, quantity, hemmingLength, storeNumber, giftCardAmount },
      { withCredentials: true },
    )
    return res
  } catch (ex) {
    console.error('Failed to add ATG Legacy Cart Item to cart', ex)
    throw new Error('Failed to add ATG Legacy Cart Item to cart')
  }
}

interface UserSessionData {
  orderId: string
}

export const useFetchAtgLegacyCart = ({ orderId }: UserSessionData) => {
  const query = useQuery({
    queryKey: ['atgLegacyCart', orderId || 'no-order-id'],
    queryFn: _fetchAtgLegacyCart(orderId),
  })

  return {
    ...query,
    data: query.data as AtgLegacyCart,
  }
}

const _fetchAtgLegacyCart = (orderId: string) => async (): Promise<AtgLegacyCart> => {
  if (!orderId) {
    return {
      items: [],
      hasMore: false,
      itemsInCart: 0,
    }
  }
  try {
    const fetchCart = await atgAxiosInstance.get('cart/commerceItems', {
      withCredentials: true,
    })

    if (fetchCart.status === 200) {
      const { data: dataFetchLegacyCart } = fetchCart
      const items =
        dataFetchLegacyCart && dataFetchLegacyCart.items
          ? dataFetchLegacyCart.items
          : dataFetchLegacyCart._embedded?.items
            ? dataFetchLegacyCart._embedded?.items
            : []

      const fetchedDataFromAtg: AtgLegacyCart = {
        items,
        hasMore: dataFetchLegacyCart.hasMore,
      }

      //commented below section as commerceitems api call is not the source of truth if the cart has items or not.
      // if (items.length <= 0) {
      //   // avoid the other calls as there are no items in the cart
      //   return undefined
      // }

      const fetchAdditionalLineItems = await fetchAdditionalLineItemAttributes()

      if (fetchedDataFromAtg && items && fetchAdditionalLineItems && fetchAdditionalLineItems) {
        fetchedDataFromAtg.items.forEach(async (cartItem) => {
          cartItem.additionalLineItemAttributes = fetchAdditionalLineItems.find(
            (lineItem: AtgAdditionalLineItemAttributes) => lineItem.commerceItemId === cartItem.id,
          )
        })
      }

      const fetchGeneralInfo = await fetchCartGeneralInfo()
      if (fetchedDataFromAtg && fetchedDataFromAtg.items && fetchCartGeneralInfo) {
        fetchedDataFromAtg.items.forEach((cartItem) => {
          const cartItemId = cartItem.id
          const cartGeneralInfo = fetchGeneralInfo.commerceItems.find((item: any) => {
            if (cartItemId === item.id) {
              return item
            }
          })
          cartItem.dxlCartGeneralInfo = {
            unitAmount: cartGeneralInfo.priceInfo.unitAmount,
            currencyCode: cartGeneralInfo.priceInfo.currencyCode,
          }
        })
      }
      fetchedDataFromAtg.totals = fetchGeneralInfo.priceInfo
      fetchedDataFromAtg.itemsInCart = fetchGeneralInfo.totalCommerceItemCount

      return { ...fetchedDataFromAtg, items: reversedItemsInCart(fetchedDataFromAtg) }
    }

    // Updating return type from `undefined` to Error
    // `undefined` is not a valid return type for React Query
    throw new Error('Failed to fetch ATG Legacy Cart - Non 200 status')
  } catch (ex) {
    throw new Error('Failed to fetch ATG Legacy Cart')
  }
}

export const updateItemLegacyCart = async ({ itemId, quantity }: { itemId: string; quantity: number }) => {
  try {
    const res = await atgAxiosInstance.patch(`cart/commerceItems/${itemId}`, { quantity }, { withCredentials: true })
    return res
  } catch (ex) {
    throw new Error(`Failed to update ATG Legacy Cart Item ${JSON.stringify(ex)}`)
  }
}

export const removeItemLegacyCart = async ({ itemId }: { itemId: string }) => {
  try {
    const res = await atgAxiosInstance.delete(`cart/commerceItems/${itemId}`, { withCredentials: true })
    return res
  } catch (ex) {
    throw new Error('Failed to remove ATG Legacy Cart Item')
  }
}

export const moveItemToWishlist = async ({ commerceItems, productId, quantity, skuId }) => {
  try {
    const res = await atgAxiosInstance.post(
      'currentUser/wishlist/move',
      { commerceItems, productId, quantity, skuId },
      { withCredentials: true },
    )
    return res
  } catch (ex) {
    throw new Error('Failed to move ATG Legacy Cart Item to Wishlist')
  }
}

export const addToWishlist = async ({
  productId,
  skuId,
  quantity,
}: {
  skuId: string
  productId: string
  quantity: number
}) => {
  try {
    const res = await atgAxiosInstance.post(
      `currentUser/wishlist/add`,
      { productId, skuId, quantity },
      { withCredentials: true },
    )
    return res
  } catch (ex) {
    console.error('Failed to add ATG PDP Item to wishlist')
  }
}

export const getAtgWishList = async () => {
  try {
    const res = await atgAxiosInstance.get(`currentUser/wishlist`, {
      withCredentials: true,
    })
    return res
  } catch (ex) {
    console.error('Failed to get user WishList')
  }
}

export const getAddressSuggestions = async (searchTerm: string) => {
  try {
    const url =
      'https://api.edq.com/capture/address/v2/search?' +
      'Auth-Token=' +
      EXPERIAN_API_TOKEN_KEY +
      '&query=' +
      searchTerm +
      '&country=USA&take=5'

    const response = await atgAxiosInstance.get(url)

    return response
  } catch (error) {
    console.log(error)
  }
}

export const formatAddressSuggestions = async (formatUrl: string) => {
  try {
    const authToken = EXPERIAN_API_TOKEN_KEY
    const url = `${formatUrl}&Auth-Token=${authToken}`

    const response = await atgAxiosInstance.get(url)

    return response
  } catch (error) {
    console.log(error)
  }
}
