export default {
  baseStyle: ({ colorMode }: any) => ({
    textTransform: 'uppercase',
    fontWeight: 700,
    letterSpacing: '0.035rem',
    lineHeight: '100%',
    paddingY: 0.5,
    paddingX: 1,
    borderRadius: '2px',
    fontSize: '14px',
  }),
  variants: {
    solid: () => ({
      bg: 'primary',
    }),
    outline: () => ({
      bg: 'white',
      color: 'primary',
      borderColor: 'primary',
    }),
    subtle: () => ({
      bg: 'highlight',
      color: 'primary',
    }),
    solidRed: {
      color: 'white',
      background: 'danger.600',
    },
  },
}
