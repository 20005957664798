import { Product } from '@Types/product/Product'
import { Variant } from '@Types/product/Variant'
import { HomePageInterface, StaticPageInterface } from 'composable/tealium-interfaces/TealiumPageInterface'
import { UtagManager } from 'frontastic/tastics/tealium/utag.manager'
import { TEALIUM_SITE_ID } from '../composable/components/general/constants'
import { AtgLegacyCart } from '../frontastic/contexts/atgCartContext/interfaces/AtgLegacyCartData'
import { StarterKitAlgoliaProduct } from 'composable/components/plp/types'
declare var window

var clpPromoClickData = []

if (typeof window !== 'undefined') {
  var utagViewQueue = new UtagManager('utagViewQueue', 100, window, function () {
    this.running = true
    if (!this.isEmpty()) {
      if (this.window.utag) {
        let utag_data = this.front()
        this.dequeue()
        this.window.utag.track(utag_data[0], utag_data[1])
      }
      setTimeout(() => {
        this.processQueue()
      }, this.speed)
    } else {
      this.running = false
    }
  })
}
const getMediaQueries = () => {
  let viewport: string

  const width = window.innerWidth
  const height = window.innerHeight
  const userAgent = navigator.userAgent
  const userAgentShowsMobile =
    userAgent.indexOf('Mobile') !== -1 || userAgent.indexOf('Mobi') !== -1 || userAgent.indexOf('Mini') !== -1

  if (width < 768 && userAgentShowsMobile) {
    viewport = 'mobile'
  } else if (width >= 768 && width <= 1024 && userAgentShowsMobile) {
    viewport = 'tablet'
  } else if (width > 1024) {
    viewport = 'desktop'
  }
  return viewport
}

const utagView = (utag_data, country_code, currency_code) => {
  if (utag_data && utag_data.length) {
    const currLocation = window.location.href

    let viewport = getMediaQueries()
    let countryData = {
      country_code: country_code,
      currency_code: currency_code === 'USD' ? '$' : currency_code,
    }
    utag_data[1].country_code = countryData['country_code']
    if (countryData['currency_code'] === '$') {
      utag_data[1].currency_code = 'USD'
    } else {
      utag_data[1].currency_code = countryData['currency_code']
    }
    utag_data[1].ismobile = viewport
    let date = new Date()
    utag_data[1].tealium_timestamp_epoch = Math.floor(date.getTime() / 1000)
    utag_data[1].tealium_timestamp_local = new Date(date.getTime() - date.getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1)
    utag_data[1].tealium_timestamp_utc = date.toISOString()
    utagViewQueue?.enqueue(utag_data)
  }
}

const utagLink = (utag_data, country_code?: string, currency_code?: string) => {
  utagView(utag_data, country_code, currency_code)
}

const promoClickUtagLinkEvent = (data: any) => {
  const promoDetails = {}
  const utagData = {}
  utagData['tealium_event'] = 'promo_click'
  promoDetails['promotion_name'] = data
  if (data && data !== '') {
    clpPromoClickData.push(promoDetails)
    window.localStorage.setItem('clpPromoClickData', JSON.stringify(clpPromoClickData))
    utagData['promotion_name'] = getClickedPromoNames()
    utagLink(['link', utagData])
  }
}

const getClickedPromoNames = () => {
  let promos = []
  const clpPromoClickData = JSON.parse(window.localStorage.getItem('clpPromoClickData'))
  clpPromoClickData?.forEach((item) => {
    if (item.promotion_name) {
      promos.push(item.promotion_name)
    }
  })
  return promos
}
const staticPathRegex = /^\/static\/[^/]+$/

const getTealiumPageName = (path: string) => {
  if (staticPathRegex.test(path)) {
    return path
  }
  return 'Home Page'
}

const getPLPCategory = (path: string) => {
  const params = path?.split('/')
  const catWithQuery = params[params.length - 1]
  const category = catWithQuery?.split('?')[0] ?? ''
  return category
}

const getTealiumPageCategory = (path: string) => {
  if (staticPathRegex.test(path)) {
    return 'editorial'
  } else if (path.indexOf('/c/') > -1) {
    return getPLPCategory(path)
  }
  return 'homepage'
}

const getTealiumPageType = (path: string) => {
  if (staticPathRegex.test(path)) {
    return 'static'
  }
  return 'home'
}

const getTealiumSiteSection = (path: string) => {
  if (staticPathRegex.test(path)) {
    return 'static/editorial'
  }
  return 'homepage'
}

const constructUtagData = (path, userDetails: any, userSessionData: any, otherParams?: any) => {
  const pageWiseUtagData = buildPageWiseUtagData(path, userDetails, userSessionData)
  const utag_data = ['view', { ...pageWiseUtagData, ...otherParams }]
  return utag_data
}

const buildBaseTealiumUtagData = (path, userDetails, userSessionData) => {
  const baseTealiumUtagData = {
    site_id: TEALIUM_SITE_ID,
    available_points: userSessionData?.firstName ? userSessionData?.userAvailablePoints : '',
    current_tier: userSessionData?.firstName ? userSessionData?.userCurrentTier : '',
    user_mystore: userSessionData?.store ? userSessionData?.store.displayStoreNumber : '',
    user_sizeprofile: userDetails?.profileSizes ? (userDetails?.profileSizes?.alwaysSbs == true ? 'on' : 'off') : 'off',
    customer_email: userDetails?.profile?.['email'] || undefined,
    customer_postal_code: userDetails?.addressList?.[0]?.postalCode || undefined,
    customer_id: userDetails?.profile?.['customerId'] || undefined,
    crm_customer_number: userDetails?.profile?.['crmId'] ? userDetails?.profile?.['crmId'] : undefined,
    isThirdParty: 'true',
    page_category: getTealiumPageCategory(path),
    cm_category_id: getTealiumPageCategory(path),
    page_type: getTealiumPageType(path),
    page_name: getTealiumPageName(path),
    site_section: getTealiumSiteSection(path),
    cm_page_id: path,
    page_url: path,
  }
  return baseTealiumUtagData
}
const buildPageWiseUtagData = (path, userDetails, userSessionData) => {
  const baseTealiumUtagData = buildBaseTealiumUtagData(path, userDetails, userSessionData)
  if (staticPathRegex.test(path)) {
    const staticPageUtagData: StaticPageInterface = {
      hashed_email: userDetails?.profile?.['email'] || undefined,
      customer_first_name: userSessionData?.firstName || undefined,
      customer_last_name: userSessionData?.lastName || undefined,
      ...baseTealiumUtagData,
    }
    return staticPageUtagData
  }

  const homePageUtagData: HomePageInterface = {
    in_iframe: 0,
    is_confirmation: 0,
    user_loginstatus: userDetails?.firstName ? true : false,
    site_breadcrumb: 'Home',
    ...baseTealiumUtagData,
  }
  return homePageUtagData
}

const getAddedProductSize = (key) => {
  const arr = key.split(' ')
  if (arr.length > 2) {
    return [arr.slice(2).join('-')]
  } else {
    return []
  }
}

// Moving this function to this file as it exists. It could be improved.
const utagAddToCart = ({
  product,
  currentVariant,
  variant,
  breadcrumbs,
  qty,
  userSessionData,
  userDetails,
  cartData,
  algoliaQueryId,
}: {
  product?: Product
  currentVariant: Variant
  variant: Variant
  qty: Number
  cartData?: AtgLegacyCart
  breadcrumbs?: any
  userSessionData: any
  userDetails: any
  algoliaQueryId: string
}) => {
  const isLoggedIn = !!userSessionData?.firstName

  const utagObj = [
    'link',
    {
      tealium_event: 'cart_add',
      product_id: [variant.attributes?.itemNumber],
      product_name: [product?.name],
      product_brand: [product?.attributes?.brand],
      product_variant: [variant.attributes?.ecommColor],
      product_list_price: currentVariant?.discountedPrice
        ? (currentVariant?.discountedPrice?.centAmount / 100).toFixed(2)
        : (currentVariant?.price?.centAmount / 100).toFixed(2),
      product_unit_price: [(currentVariant?.price?.centAmount / 100).toFixed(2)],
      product_quantity: [qty],
      product_size: getAddedProductSize(currentVariant?.key),
      product_color: [variant.attributes?.ecommColor],
      product_sku: [variant.sku],
      site_id: 'dxl',
      site_section: [breadcrumbs?.[0]?.label ?? undefined],
      site_sub_section: [breadcrumbs?.[1]?.label ?? undefined],
      site_sub_section_level3: [breadcrumbs?.[2] && breadcrumbs?.[2]?.link ? breadcrumbs?.[2]?.label : undefined],
      product_category: [breadcrumbs?.[0]?.label ?? undefined],
      product_category2: [breadcrumbs?.[1]?.label ?? undefined],
      product_category3: [breadcrumbs?.[2] && breadcrumbs?.[2]?.link ? breadcrumbs?.[2]?.label : undefined],
      event_type: 'product details',
      hashed_email: userDetails?.profile?.['email'] || undefined,
      customer_email: userDetails?.profile?.['email'] || undefined,
      customer_postal_code: userDetails?.addressList?.[0]?.postalCode || undefined,
      customer_id: userDetails?.profile?.['customerId'] || undefined,
      crm_customer_number: userDetails?.profile?.['crmId'] ? userDetails?.profile?.['crmId'] : undefined,
      user_loginstatus: isLoggedIn,
      product_algolia_query_id: [algoliaQueryId ?? ''],
      site_breadcrumb: breadcrumbs?.map((breadcrumb) => breadcrumb.label).join(' > '),
      order_grand_total: [cartData?.totals?.convAmount],
      ...JSON.parse(localStorage.getItem('lastProductClicked')),
    },
  ]
  utagLink(utagObj)
}
const utagAddToWishlist = ({
  product,
  currentVariant,
  breadcrumbs,
  qty,
  userSessionData,
  cartData,
  algoliaQueryId,
}: {
  product?: Product
  currentVariant: Variant
  qty: Number
  cartData?: AtgLegacyCart
  breadcrumbs?: any
  userSessionData: any
  algoliaQueryId: string
}) => {
  const isLoggedIn = !!userSessionData?.firstName

  const utagObj = [
    'link',
    {
      tealium_event: 'wishlist_add',
      product_algolia_query_id: [algoliaQueryId ?? ''],
      product_id: [currentVariant.attributes?.itemNumber],
      product_name: [product.name],
      site_section: [breadcrumbs?.[0]?.label ?? undefined],
      site_sub_section: [breadcrumbs?.[1]?.label ?? undefined],
      site_sub_section_level3: [breadcrumbs?.[2] && breadcrumbs?.[2]?.link ? breadcrumbs?.[2]?.label : undefined],
      product_category: [breadcrumbs?.[0]?.label ?? undefined],
      product_category2: [breadcrumbs?.[1]?.label ?? undefined],
      product_category3: [breadcrumbs?.[2] && breadcrumbs?.[2]?.link ? breadcrumbs?.[2]?.label : undefined],
      site_sub_section_level4: [breadcrumbs?.[3] ? breadcrumbs?.[3].label : undefined],
      product_color: currentVariant.attributes?.ecommColor ? [currentVariant.attributes?.ecommColor] : [],
      product_list_price: [
        currentVariant?.discountedPrice
          ? (currentVariant?.discountedPrice?.centAmount / 100).toFixed(2)
          : (currentVariant?.price?.centAmount / 100).toFixed(2),
      ],
      product_unit_price: [(currentVariant?.price?.centAmount / 100).toFixed(2)],
      product_variant: [currentVariant.attributes?.ecommColor],
      product_size: getAddedProductSize(currentVariant?.key),
      product_sku: [currentVariant?.sku],
      product_quantity: [qty],
      order_grand_total: [cartData?.totals?.convAmount],
    },
  ]
  utagLink(utagObj)
}
export const utagProductClick = (
  record: Partial<StarterKitAlgoliaProduct>,
  color_name: string,
  prodctId: string,
  userSessionData: any,
  algoliaQueryId: string,
) => {
  let price: string | number = record.price?.centAmount?.[0] || 0
  price /= 100
  price = price.toFixed(2)
  const utagData = [
    'link',
    {
      tealium_event: 'product_click',
      product_id: [record?.key],
      product_name: record?.name ? [record?.name] : [],
      product_variant: [color_name ?? record?.colorFamily ?? ''],
      product_category: record.categories || [],
      product_price: [price || ''],
      product_impression_position: record?.__position ? [(Number(record?.__position) || 0) + 1] : [],
      product_list: ['search - search'],
      product_algolia_query_id: [algoliaQueryId ?? ''],
    },
  ]
  utagView(utagData, userSessionData?.dxlCountry, userSessionData?.dxlCurrency)
}

export {
  utagView,
  utagLink,
  getClickedPromoNames,
  promoClickUtagLinkEvent,
  constructUtagData,
  utagAddToCart,
  utagAddToWishlist,
}
