import { Cart } from '@Types/cart/Cart'
import { LineItem } from '@Types/cart/LineItem'
import convertToCents from './convertToCents'
import { AtgLegacyCart, AtgLegacyCartItem } from '../interfaces/AtgLegacyCartData'

export const removeWord = (description, brand) =>
  description
    .replace(new RegExp(`\\b${brand}\\b`, 'gi'), '')
    .replace(/\s{2,}/g, ' ')
    .trim()

export const raiseToast = (title: string, description: string, statusMessage: 'success' | 'error' | 'info', toast) => {
  if (!toast) return null

  return toast({
    title: title,
    description: description,
    status: statusMessage,
    duration: 4000,
    isClosable: true,
    position: 'top',
  })
}

const extractProductURL = ({ url, swatch }: { url: string; swatch: string }) => {
  return url ? `${url}${swatch ? `?swatch=${encodeURI(swatch)}` : ''}` : null
}

const getSaleMessage = (priceInfo, salePrice) => {}

const calculateItemDiscountAndSaleMessage = (priceInfo) => {
  let saleMessageHTML = ''
  let saleprice = 0

  if (priceInfo && priceInfo.convRawTotalPrice > priceInfo.convAmount) {
    const { currentPriceDetailsSorted, adjustments } = priceInfo
    const isProrated = currentPriceDetailsSorted[0].prorated

    if (isProrated) {
      saleprice =
        priceInfo.convRawTotalPrice -
        currentPriceDetailsSorted.reduce((proratedPrice, detail) => {
          return (
            proratedPrice +
            (detail.prorated ? detail.convProratedAmount : detail.convProratedUnitPrice * detail.quantity)
          )
        }, 0)
    } else {
      saleprice = priceInfo.convRawTotalPrice - priceInfo.convAmount
    }

    let checksaleBulkMsgCount = 0
    let checkItemDiscountCount = 0

    adjustments?.forEach((adjustment) => {
      const { adjustmentDescription, convNextTierSaleMessage, convPromoName } = adjustment

      if (adjustmentDescription?.toLowerCase() === 'sale bulk price') {
        checksaleBulkMsgCount++
        if (convNextTierSaleMessage) {
          saleMessageHTML += convNextTierSaleMessage
        }
      }

      if (adjustmentDescription === 'Item Discount' && convPromoName) {
        checkItemDiscountCount++
        saleMessageHTML += saleMessageHTML ? `<br>${convPromoName}` : convPromoName
      }
    })

    if (
      saleprice > 0 &&
      checkItemDiscountCount === 0 &&
      checksaleBulkMsgCount === 0 &&
      currentPriceDetailsSorted[0].promoName
    ) {
      saleMessageHTML += `<br>${currentPriceDetailsSorted[0].promoName}`
    }

    if (checkItemDiscountCount === 0 && checksaleBulkMsgCount === 0) {
      currentPriceDetailsSorted.forEach((detail) => {
        if (detail.promoName) {
          saleprice = priceInfo.convAmount - detail.convProratedUnitPrice * detail.quantity
          saleMessageHTML += `<br>${detail.promoName}`
        }
      })
    }
  }

  return {
    discountAmount: convertToCents(Number(saleprice.toFixed(2))),
    saleMessage: saleMessageHTML,
  }
}

const getItemPrice = (priceInfo) => {
  let itemPrice: number = 0
  if (priceInfo) {
    for (let i = 0; i < priceInfo.currentPriceDetailsSorted.length; i++) {
      itemPrice +=
        priceInfo.currentPriceDetailsSorted[i].convProratedUnitPrice * priceInfo.currentPriceDetailsSorted[i].quantity
    }
  }
  return itemPrice
}

export const formatCart = (cart: AtgLegacyCart, userSessionData) => {
  // you can refer to additionalLineItemAttributesResponse.json file in atgCartContext/responseJsonFilesForReference for the API response
  const lineItems: LineItem[] = cart?.items?.map((item: AtgLegacyCartItem) => {
    const additionalLineItemAttributesCommerceItem = item?.additionalLineItemAttributes
    const commerceItem = additionalLineItemAttributesCommerceItem?.commerceItem
    const auxiliaryData = commerceItem?.auxiliaryData
    const availabilityStatus = item?.additionalLineItemAttributes?.availabilityStatus

    const priceInfo = commerceItem?.priceInfo

    const promoDiscounts = calculateItemDiscountAndSaleMessage(priceInfo)
    let itemPrice = 0
    if (priceInfo?.convSalePrice > item?.additionalLineItemAttributes.commerceItem.convCustomizedUnitPrice) {
      const price = getItemPrice(priceInfo)
      itemPrice = convertToCents(
        price +
          item?.additionalLineItemAttributes.commerceItem.convCustomizedUnitPrice *
            item?.additionalLineItemAttributes.quantity,
      )
    } else {
      itemPrice = convertToCents(priceInfo.convAmount)
    }

    const saleQuantity = priceInfo.currentPriceDetailsSorted[0].quantity
    const salePricePerUnit = priceInfo.adjustments[0].convAdjustment
    const totalSalePrice = priceInfo.adjustments[0].convTotalAdjustment

    const discountedPricePerUnit = priceInfo.adjustments[1]?.convAdjustment
    const totalDiscount = priceInfo.bulkPriceApplied
      ? totalSalePrice - Math.abs(priceInfo.adjustments[1]?.convTotalAdjustment)
      : priceInfo.adjustments[1]?.convTotalAdjustment || 0
    const totalDiscountedPrice = totalSalePrice - Math.abs(totalDiscount)

    const hemmingAdjustment = priceInfo.adjustments.find((adjustment) => 'customChargeInfo' in adjustment)
    const webURL = commerceItem?.webURL
    const productColorName = auxiliaryData?.catalogRef?.colorName

    return {
      lineItemId: item?.id,
      masterData: {
        current: {
          masterVariant: {
            attributes: [
              { name: 'segment2Label', value: 'Size' },
              { name: 'segment3Label', value: 'Inseam' },
              { name: 'ecommSize', value: item?.sku?.displayName },
              {
                name: 'brand',
                value: auxiliaryData?.productRef?.brand,
              },
            ],
          },
        },
      },
      productId: item?.productId,
      productSlug: item?.product?.displayName,
      name: removeWord(auxiliaryData?.productRef?.description ?? '', auxiliaryData?.productRef?.brand ?? ''), //remove brand value from the description to match how it appears in CT
      inStoreItem: item?.additionalLineItemAttributes?.inStoreItem,
      type: item?.commerceItemClassType,
      count: saleQuantity,
      price: {
        centAmount: convertToCents(salePricePerUnit),
        currencyCode: userSessionData?.dxlCurrency,
      }, // Price of a single item
      discountedPrice: {
        centAmount: convertToCents(discountedPricePerUnit),
        currencyCode: userSessionData?.dxlCurrency,
      }, // Discounted price per item
      isDiscounted: totalDiscountedPrice > 0 ? totalSalePrice - totalDiscountedPrice > 0 : false,
      discounts: [],
      totalPrice: {
        centAmount: convertToCents(totalSalePrice),
        currencyCode: userSessionData?.dxlCurrency,
      },
      totalDiscountedPrice: {
        centAmount: convertToCents(totalDiscountedPrice), // this needs to be mapped in an upcoming discount ticket - DDC-76
        currencyCode: userSessionData?.dxlCurrency,
      },
      promoDiscounts,
      itemFinalDisplayPrice: itemPrice,
      variant: {
        images: [
          item?.commerceItemClassType === 'giftCardCommerceItem'
            ? `//images.dxl.com/is/image/CasualMale/p${item?.productId}`
            : `//images.dxl.com/is/image/CasualMale/p${item?.productId}${item?.additionalLineItemAttributes?.commerceItem?.auxiliaryData?.catalogRef?.imageColorCode}?$product$`,
        ],
        prices: [
          {
            custom: {
              centAmount: convertToCents(salePricePerUnit),
              currencyCode: userSessionData?.dxlCurrency,
            },
          },
        ],
        isOnStock: item?.additionalLineItemAttributes?.inStock ?? false,
        availableQuantity: item.availableCTInventory, // to be fixed in DDC-921 ticket
        sku: item?.catalogRefId,
        attributes: {
          commerceItemId: item?.additionalLineItemAttributes?.commerceItemId,
          removalId: `${item?.id}:${item?.additionalLineItemAttributes?.relationShipId}`,
          ecommColor: item?.additionalLineItemAttributes?.commerceItem?.auxiliaryData?.catalogRef?.colorName,
          itemNumber: item?.productId,
          segment2: item?.additionalLineItemAttributes?.sizes?.[0],
          segment3: item?.additionalLineItemAttributes?.sizes?.[1],
        },
      },
      availabilityStatus,
      isGift: null,
      _url: extractProductURL({ url: webURL, swatch: productColorName }),
      availableDiscount: null,
      ...(hemmingAdjustment && { hemmingPriceInfo: hemmingAdjustment?.customChargeInfo }),
      ...(hemmingAdjustment && { hemmingPrice: hemmingAdjustment?.totalAdjustment }),
    }
  })
  const formattedCart: Partial<Cart> | undefined = {
    cartId: '',
    cartVersion: '',
    cartState: 'Active',
    lineItems,
    email: '',
    birthday: null,
    shippingInfo: null,
    availableShippingMethods: [], // Available shipping methods for this cart
    shippingAddress: {},
    billingAddress: {},
    sum: {
      centAmount: convertToCents(cart?.totals?.convAmount),
      currencyCode: userSessionData?.dxlCurrency,
    },
    payments: null,
    discountCodes: null,
    taxed: null,
    locale: {
      language: 'en',
    },
  }

  return formattedCart
}

export const reversedItemsInCart = (cart) => {
  return cart.items.sort((a, b) => {
    const dateA = new Date(a.additionalLineItemAttributes.commerceItem.timeAdded)
    const dateB = new Date(b.additionalLineItemAttributes.commerceItem.timeAdded)
    return Math.floor(dateB.getTime() / 1000) - Math.floor(dateA.getTime() / 1000)
  })
}
