import { defineStyleConfig } from '@chakra-ui/styled-system'

export default defineStyleConfig({
  sizes: {
    '3xl': {
      height: '1.5rem',
      width: '1.5rem',
      fontSize: '1rem',
    },
  },
})
