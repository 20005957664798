import { Cell as LayoutElementType } from 'frontastic'

const GRID_COLUMNS_SIZE = 12

// - grid responsiveness based on this table https://docs.commercetools.com/frontend-studio/using-the-page-builder#edit-a-layout-element
const GRID_ITEMS_RESPONSIVE_SIZES = {
  12: {
    mobile: 12,
    tablet: 12,
    desktop: 12,
  },
  6: {
    mobile: 12,
    tablet: 6,
    desktop: 6,
  },
  4: {
    mobile: 12,
    tablet: 6,
    desktop: 4,
  },
  8: {
    mobile: 12,
    tablet: 6,
    desktop: 8,
  },
  3: {
    mobile: 12,
    tablet: 6,
    desktop: 3,
  },
  2: {
    mobile: 6,
    tablet: 2,
    desktop: 2,
  },
  // This grid option was not in the docs, setting some values as default
  9: {
    mobile: 12,
    tablet: 12,
    desktop: 9,
  },
}

/**
 * Takes the page's section elements and groups them
 * based on a 12fr grid size
 */
export const convertToRows = (layoutElements: LayoutElementType[] = []) => {
  const rows = layoutElements.reduce(
    (acc, gridElement) => {
      const gridSize = gridElement.configuration.size
      if (gridSize === GRID_COLUMNS_SIZE) {
        return {
          // insert new row and reset currentGridSize
          currentGridSize: 0,
          result: [...acc.result, [gridElement]],
        }
      }

      const nextGridSize = acc.currentGridSize + gridSize
      if (nextGridSize > GRID_COLUMNS_SIZE) throw new Error('exceeded grid size')
      // if currentGridSize is 0, the last row is already complete, and it should start a new one.
      // if its not 0, there's still room for more elements
      const lastGridItem = acc.currentGridSize === 0 ? [] : acc.result.pop()
      const newLastGridItem = [...lastGridItem, gridElement]
      return {
        // insert row and reset the currentGridSize if it reaches the max
        currentGridSize: nextGridSize === GRID_COLUMNS_SIZE ? 0 : nextGridSize,
        result: [...acc.result, newLastGridItem],
      }
    },
    { currentGridSize: 0, result: [] },
  )

  return rows.result as LayoutElementType[][]
}

export const getGridItemSize = ({
  viewport,
  originalSize,
}: {
  viewport: 'mobile' | 'tablet' | 'desktop'
  originalSize: number
}): number => GRID_ITEMS_RESPONSIVE_SIZES[originalSize]?.[viewport]
