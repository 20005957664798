import { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import Script from 'next/script'
import { utagView, constructUtagData } from 'helpers/tealiumHelper'
import { useAtgUser } from 'frontastic/contexts'
import { COMPOSABLE_ENV, IS_PREVIEW_MODE, LOCALE } from '../../../composable/components/general/constants'
declare global {
  interface Window {
    Optanon: any
    OneTrust: {
      changeLanguage: (lang: string) => void
      OnConsentChanged: (callback: Function) => void
    }
    OptanonWrapper: () => void
    dataLayer: Record<string, string>[]
  }
}
interface CookieProProps {
  scriptVersionId: string
}

export const CookieProBase = ({ scriptVersionId }: CookieProProps) => {
  const [isOneTrustLoaded, setIsOneTrustLoaded] = useState<boolean>(false)
  const router = useRouter()
  const path = router.asPath
  const { userDetails, userSessionData } = useAtgUser()
  const [isUtagViewCalled, setIsUtagViewCalled] = useState(false)

  const fireUtagViewEvent = (userDetails, userSessionData, path) => {
    if (userDetails && userSessionData) {
      if (!isUtagViewCalled) {
        const utag_data = constructUtagData(path, userDetails, userSessionData)
        utagView(utag_data, userSessionData?.dxlCountry, userSessionData?.dxlCurrency)
        setIsUtagViewCalled(true)
      }
    }
  }

  const waitForOneTrustWindowObject = (userDetails, userSessionData, path) => {
    let counter = 1
    const locateOneTrustWindowObject = setInterval(() => {
      if (!!window.OneTrust) {
        window.OneTrust.OnConsentChanged(() => {
          fireUtagViewEvent(userDetails, userSessionData, path)
        })
        clearInterval(locateOneTrustWindowObject)
      } else {
        if (counter > 9) {
          clearInterval(locateOneTrustWindowObject)
        }
        counter++
      }
    }, 500)
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && isOneTrustLoaded) {
      if (userDetails && userSessionData) {
        waitForOneTrustWindowObject(userDetails, userSessionData, path)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOneTrustLoaded, userDetails, userSessionData, path])

  if (!scriptVersionId) {
    throw new Error('CookieProTest requires a scriptVersionId')
  }

  const locale = LOCALE
  useEffect(() => {
    const OneTrust = window?.OneTrust
    const lang = locale.split('-')[0].toLocaleLowerCase()
    if (OneTrust) {
      OneTrust.changeLanguage(lang)
    }
  }, [locale])
  const suffix = IS_PREVIEW_MODE || COMPOSABLE_ENV === 'local' ? '-test' : ''
  const optanonWrapper = () => {
    // Refer to OptanonWrapper callback documentation:
    // https://my.onetrust.com/s/article/UUID-29158b4e-22f6-0067-aa36-94f3b8cf3561
    if (typeof window !== 'undefined') {
      window.OptanonWrapper = function () {
        window.dataLayer?.push({
          event: 'OneTrustGroupsUpdated',
        })
      }
    }
  }

  return (
    <>
      <Script
        id="cookiepro"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        type="text/javascript"
        data-domain-script={scriptVersionId + suffix}
        data-document-language="true"
        onLoad={() => optanonWrapper()}
        onReady={() => setIsOneTrustLoaded(true)}
      />
    </>
  )
}
